import { Link } from "react-router-dom";
import useApiList from "../hooks/use-api-list";

export default function ApiListPage() {
  const { loading, data } = useApiList();

  if (loading) return <>Loading...</>;

  return (
    <div style={{ margin: 16 }}>
      <h1>API Documentation</h1>
      <p>Please select the documentation file from the list.</p>
      <ul>
        {data?.map(api => (
          <li key={api.name}>
            <Link to={api.name}>{api.description}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
