import { Link } from "react-router-dom";

export default function ApiNotFound() {
  return (
    <div style={{ margin: 16 }}>
      <h1>Not Found</h1>
      <p>The API you're looking for is not found in the list. Please check again the URL.</p>
      <p>
        <Link to="/">Go Home</Link>
      </p>
    </div>
  );
}
