import { useParams } from "react-router-dom";
import { RedocStandalone, SideNavStyleEnum } from "redoc";
import ApiNotFound from "./api-not-found";
import useVerifySpecExists from "../hooks/use-verify-spec-exists";
import useApiList from "../hooks/use-api-list";

function ViewApi({ path }: { path: string }) {
  const exists = useVerifySpecExists(path);

  if (exists === null) {
    return <>Loading...</>;
  } else if (exists === false) {
    return <ApiNotFound />;
  }

  return (
    <RedocStandalone
      options={{
        generatedPayloadSamplesMaxDepth: 10,
        nativeScrollbars: true,
        expandResponses: "all",
        onlyRequiredInSamples: false,
        sideNavStyle: SideNavStyleEnum.PathOnly,
      }}
      specUrl={path}
    />
  );
}

export default function ViewApiPage() {
  const { id } = useParams();
  const { loading, data } = useApiList();

  if (loading) return null;

  const path = data?.find(api => api.name === id)?.path;

  if (!path) return <ApiNotFound />;

  return <ViewApi path={path} />;
}
