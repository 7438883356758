import useFetch from "./use-fetch";

export interface OpenApiDescriptor {
  name: string;
  path: string;
  description: string;
}

export default function useApiList(): { loading: boolean; data: OpenApiDescriptor[] | undefined } {
  const { loading, data } = useFetch<OpenApiDescriptor[]>("/api-list.json");

  return { loading, data };
}
