import { useEffect, useState } from "react";

export interface UseFetch<T> {
  loading: boolean;
  error: Error | undefined;
  status: number | undefined;
  data: T | undefined;
}

const defaultOptions = { json: true };

export default function useFetch<T>(url: string, options?: RequestInit & { json?: true }): UseFetch<T>;
export default function useFetch<T extends string = string>(url: string, options: RequestInit & { json: false }): UseFetch<T>;
export default function useFetch<T>(url: string, options?: RequestInit & { json?: boolean }): UseFetch<T>;
export default function useFetch<T>(url: string, options: RequestInit & { json?: boolean } = defaultOptions): UseFetch<T> {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [status, setStatus] = useState<number>();
  const [data, setData] = useState<T>();

  useEffect(() => {
    const ctrl = new AbortController();
    async function doFetch() {
      try {
        const response = await fetch(url, { ...options, signal: ctrl.signal });
        setStatus(response.status);
        const data = (await (options.json !== false ? response.json() : response.text())) as T;
        setData(data);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    }
    doFetch();
    return () => {
      ctrl.abort();
    };
  }, [url, options]);

  return { loading, error, status, data };
}
