import { BrowserRouter, Routes, Route } from "react-router-dom";

import ApiListPage from "./components/api-list-page";
import ViewApiPage from "./components/view-api-page";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ApiListPage />} />
        <Route path="/:id" element={<ViewApiPage />} />
      </Routes>
    </BrowserRouter>
  );
}
